export const ApiEndpointUrl = "https://onlinebicycles.in/api/";
// export const ApiEndpointUrl = "http://127.0.0.1:8000/api/";
export const storageUrl = "https://onlinebicycles.in/public/storage/";
export const clientLogoUrl = "https://onlinebicycles.in/public/cache/large/";
export const frontUrl = "https://onlinebicycles.in";
// export const frontUrl = "http://localhost:3000";
export const Logintoken = () => {
  if (localStorage.getItem("auth") != undefined) {
    return JSON.parse(localStorage.getItem("auth")).token;
  }
  return "";
};
export default {};
