import { cartPoint } from './endpoint'
import { Logintoken } from "../../common";
import { authCheckNull } from "../../common/AuthCheck";
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";

/******* Delete Cart Item */
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

/**** CART LIST REQUEST *****/
export const CART_LIST_REQUEST = 'CART_LIST_REQUEST';
export const CART_LIST_SUCCESS = 'CART_LIST_SUCCESS';
export const CART_LIST_FAILED = 'CART_LIST_FAILED';

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount=1,
  selectedProductColor,
  selectedProductSize
) => {

   var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return dispatch => {
   
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null
      }
    });
    var configuration ={};
    if(item.variants.length>0)
    {
        configuration= {quantityCount,is_configurable:true, "selected_configurable_option": item.selectedvariations.id,
                                  'super_attribute':{...item.variations}}
    }
    else
    {
      
       configuration = { quantity: quantityCount, is_configurable: false };
    }
    fetch(cartPoint.add + item.id + logintoken, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "same-origin", // include, *same-origin, omit
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        "Cookie": document.cookie
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(configuration),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
      

         if (response.error != undefined) {
             if (addToast) {
               addToast(response.error.message, {
                 appearance: "error",
                 autoDismiss: true,
               });   
             }
         } else {
             if (addToast) {
               addToast("Added To Cart", {
                 appearance: "success",
                 autoDismiss: true,
               });
             }
        
           dispatch(cartItemListGet());
         }
       
        //return response;
      })
      .catch(function (thrown) {});
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) => (
    new Promise((resolve) => {
        setTimeout(() => {
dispatch({ type: CART_LIST_REQUEST, items: [] });
            fetch(cartPoint.delete + item.id + logintoken)
              .then(function (response) {
                return response.json();
              })
              .then(function (response) {
                console.log("response coming here", response);
                dispatch({ type: DELETE_FROM_CART, payload: item });
                addToast("Removed From Cart", {
                  appearance: "success",
                  autoDismiss: true,
                });
                dispatch(cartItemListGet());
                resolve();
                //return response;
              })
              .catch(function (thrown) {
                addToast("some error occured", {
                  appearance: "error",
                  autoDismiss: true,
                });
              });
           
        }, 500);
    })
);
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
    var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
dispatch({ type: CART_LIST_REQUEST, items: [] });
      new Promise((resolve) => {
        setTimeout(() => {

            fetch(cartPoint.empty + logintoken)
              .then(function (response) {
                return response.json();
              })
              .then(function (response) {
                console.log("response coming here", response);

                addToast("Removed All From Cart", {
                  appearance: "error",
                  autoDismiss: true,
                });
                dispatch({ type: DELETE_ALL_FROM_CART });
                dispatch(cartItemListGet());
                resolve();
                //return response;
              })
              .catch(function (thrown) {
                addToast("some error occured", {
                  appearance: "error",
                  autoDismiss: true,
                });
              });
           
        }, 500);
    })

    
    }
  
  };
};

export function cartRemoveItem(item,productid='',addToast) {
  // sending request to server, timeout is used as a stub

}


// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation;
    //  .filter(single => single.color === color)[0]
     // .size.filter(single => single.name === size)[0].stock;
  }
};

/********** Get  Cart list *********/

export function cartListItemSuccess(items) {
  return {
      type: CART_LIST_SUCCESS,
      items:items.data.items !=undefined?items.data.items:[],
      subtotal:items.data.formated_sub_total,
      grandtotal:items.data.formated_grand_total,  
      coupon_code:items.data.coupon_code,
      discount:items.data.formated_discount  
  };
}

export function cartListItemFailed(error) {
  return {
      type: CART_LIST_FAILED,
      error,
  };
}

export function cartItemListGet()
{
  var logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
  return (dispatch) => (

    
      new Promise((resolve) => {
          setTimeout(() => {
            dispatch({type:CART_LIST_REQUEST,items:[]});
              fetch(cartPoint.list+logintoken, {
                method: 'GET',
                credentials: 'include',
                // headers: {
                //   "Cookie": document.cookie
                // },
              }) 
              .then(function (response) {
                  return response.json();
              })
              .then(function (response) {
                  console.log("response coming here",response)
                  dispatch(cartListItemSuccess(response));
                  resolve();
                  //return response;
              })
              .catch(function (thrown) {
                 ///toast.error(thrown.message);
                 dispatch(cartListItemFailed(thrown));
              });
             
          }, 500);
      })
  );

}

