import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle";
import brandLogoData from "../../data/brand-logos/brand-logo-one.json";
import SectionTitle from "../../components/section-title/SectionTitle";
import { connect } from "react-redux";
import { clientLogoUrl } from '../../common';

const BrandLogoSliderFive = ({ spaceBottomClass, spaceTopClass, grayscale, brands }) => {
  const settings = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 5,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <div
      className={`brand-logo-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }  ${spaceTopClass ? spaceTopClass : ""}`}
    >
      <div className="container">
        <SectionTitle
          titleText="Our Brands"
          positionClass="text-center"
          spaceClass="mb-55"
        />

        <div className="brand-logo-active">
          <div className="row mb-4">
            { brands.items.map( (brand, key) => {
                return (
                  <div className="col-md-2 brand-logo">
                    <Link  to={ process.env.PUBLIC_URL + "/catalog?brand=" + brand.id}>
                      <img src={clientLogoUrl + brand.swatch_value} />
                    </Link>
                  </div>
                );
              })
            }
          </div>
          {/* <Swiper {...settings}>
            {brandLogoData &&
              brandLogoData.map((single, key) => {
                return (
                  <BrandLogoOneSingle
                    data={single}
                    key={key}
                    sliderClassName="swiper-slide"
                  />
                );
              })}
          </Swiper> */}
        </div>
      </div>
    </div>
  );
};

BrandLogoSliderFive.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  grayscale: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    brands: state.brands
  }
}

const mapDispatchToProps = () => {}

export default connect(mapStateToProps, mapDispatchToProps)(BrandLogoSliderFive);
