import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { getBrands } from "../../redux/product";
import { cartItemListGet, addCoupon, RemoveCoupon } from "../../redux/cart";

const NavMenu = ({
  strings,
  cartItemListGet,
  menuWhiteClass,
  sidebarMenu,
  brands,
  category,
  spares,
  accessories,
  getBrands,
}) => {
  console.log("Brands List ", brands);

  const [categoryImage, setCategoryImage] = useState("");

  useEffect(() => {
    cartItemListGet();
    getBrands();
  }, []);
  console.log("category List  coming here ", category);
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              ABOUT
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/page/about-us"}>
                  About us
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                  {strings["wishlist"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/contact"}>Contact us</Link>
              </li>
            </ul>
          </li> */}

          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              SHOP BY BRANDS
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu mega-menu-padding spares-accessories-megamenu">
              <li className="accessories-column">
                <ul class="sub-menu-accessories">
                  <li className="mega-menu-title">
                    <Link to="/">Spares & Accessories </Link>
                  </li>
                  {brands.items.map((value, index) => {
                    if (index < 4) {
                      return false;
                    }
                    return (
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            "/catalog?brand=" +
                            value.id
                          }
                        >
                          {value.admin_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="spares-column">
                <ul>
                  <li className="mega-menu-title">
                    <Link to="/catalog/spares">Bikes</Link>
                  </li>
                  {brands.items.map((value, index) => {
                    if (index > 3) {
                      return false;
                    }
                    return (
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            "/catalog?brand=" +
                            value.id
                          }
                        >
                          {value.admin_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/#"}>
              {" "}
              BIKES CATEGORIES
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu bike-categories-menu">
              <li>
                <ul>
                  {category.items.map((values, index) => {
                    return (
                      <li
                        onMouseOver={() => setCategoryImage(values.image_url)}
                      >
                        <Link
                          to={
                            process.env.PUBLIC_URL + "/catalog/" + values.slug
                          }
                        >
                          {values.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <div className="bike-categories-menu-img-container">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        <img
                          src={
                            category.items.length > 6
                              ? categoryImage
                                ? categoryImage
                                : category.items[0].image_url
                              : process.env.PUBLIC_URL +
                                "/assets/img/banner/banner-12.png"
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              SPARES & ACCESSORIES
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu mega-menu-padding spares-accessories-megamenu">
              <li className="spares-column">
                <ul>
                  <li className="mega-menu-title">
                    <Link to="/">SPARES</Link>
                  </li>
                  <ul className="sub-menu-accessories">
                    {spares.map((values, index) => {
                      return (
                        <li>
                          <Link
                            to={
                              process.env.PUBLIC_URL + "/catalog/" + values.slug
                            }
                          >
                            {values.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </li>

              <li className="accessories-column">
                <ul>
                  <li className="mega-menu-title">
                    <Link to={process.env.PUBLIC_URL + "/catalog/accessories"}>
                      ACCESSORIES
                    </Link>
                  </li>
                  <ul className="sub-menu-accessories">
                    {accessories.map((values, index) => {
                      return (
                        <li>
                          <Link
                            to={
                              process.env.PUBLIC_URL + "/catalog/" + values.slug
                            }
                          >
                            {values.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/product-registration"}>
              REGISTER MY BIKE
            </Link>
          </li>

          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu mega-menu-padding">
             
             
            </ul>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

const changeImage = (image) => {};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    category: state.category,
    brands: state.brands,
    spares: state.childcat.spares,
    accessories: state.childcat.accessories,
  };
};

const mapDispatchToProps = {
  getBrands: getBrands,
  cartItemListGet: cartItemListGet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(NavMenu));
