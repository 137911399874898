import React from "react";

const MobileSearch = () => {
  return (
    <div className="offcanvas-mobile-search-area">
     <form action={"/catalog/root"}>
            <input type="text" placeholder="Search" name="name" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
    </div>
  );
};

export default MobileSearch;
