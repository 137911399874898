export const PAGE_LIST = "PAGE_LIST";
export const PAGE_LIST_FAILED = "PAGE_LIST_FAILED";
export const PAGE_LIST_SUCCESS = "PAGE_LIST_SUCCESS";

/****** contact Request  */
export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_REQUEST_FAILED = "CONTACT_REQUEST_FAILED";
export const CONTACT_REQUEST_SUCCESS = "CONTACT_REQUEST_SUCCESS";

/****** Product Registration Request  */
export const PRODUCT_REGISTRATION_REQUEST = "PRODUCT_REGISTRATION_REQUEST";
export const PRODUCT_REGISTRATION_REQUEST_FAILED =
  "PRODUCT_REGISTRATION_REQUEST_FAILED";
export const PRODUCT_REGISTRATION_REQUEST_SUCCESS =
  "PRODUCT_REGISTRATION_REQUEST_SUCCESS";

/****** contact Subscribe Request  */
export const CONTACT_REQUEST_SUBSCRIBE = "CONTACT_REQUEST_SUBSCRIBE";
export const CONTACT_REQUEST_SUBSCRIBE_FAILED =
  "CONTACT_REQUEST_SUBSCRIBE_FAILED";
export const CONTACT_REQUEST_SUBSCRIBE_SUCCESS =
  "CONTACT_REQUEST_SUBSCRIBE_SUCCESS";
